import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Icon = makeShortcode("Icon");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "design-et-expérience-utilisateur",
      "style": {
        "position": "relative"
      }
    }}>{`Design et Expérience Utilisateur`}<a parentName="h1" {...{
        "href": "#design-et-exp%C3%A9rience-utilisateur",
        "aria-label": "design et expérience utilisateur permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Il existe tout un monde d'excellents outils de conception. Là encore, nous visons à vous fournir une liste très organisée d'outils que nous utilisons nous-mêmes `}<em parentName="p">{`régulièrement`}</em>{` et qui ont eu un impact profond sur la façon dont nous travaillons et la qualité de ce que nous sommes capables de produire.`}</p>
    <h2 {...{
      "id": "études-de-cas-et-tests-utilisateurs",
      "style": {
        "position": "relative"
      }
    }}>{`Études de cas et Tests Utilisateurs`}<a parentName="h2" {...{
        "href": "#%C3%A9tudes-de-cas-et-tests-utilisateurs",
        "aria-label": "études de cas et tests utilisateurs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Au lieu de vous lier à Figma - qui est l'outil de conception que nous utilisons nous-mêmes - nous avons deux choses infiniment plus utiles, toutes deux offertes en cadeau par Andrej de Deepwork Studios, à qui nous sommes éternellement reconnaissants.`}</p>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Études de cas`}</strong></p>
        <p>{`Chacune des études que Deepwork a publiées ici contient littéralement tous les aspects de leur processus de conception. Vous pouvez retrouver et bifurquer tous leurs fichiers Figma, leurs cartes Miro, leurs tests UX et leurs prototypes. C'est vraiment incroyable au début. Si vous êtes un designer ou un chercheur UX, il s'agit littéralement d'un véritable trésor .`}</p>
        <Button to="https://deepwork.studio/case-studies/" sx={{
          alignSelf: 'flex-start',
          mt: 'auto'
        }} mdxType="Button">
  Explore
        </Button>
      </Flex>
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Guide de test utilisateur`}</strong></p>
        <p>{`Un guide étape par étape, très détaillé, vraiment utile, pour exécuter des tests utilisateur rapides, efficaces et perspicaces. Il n'est pas nécessaire d'interroger des milliers de personnes - il suffit de `}<a parentName="p" {...{
            "href": "https://www.nngroup.com/articles/why-you-only-need-to-test-with-5-users/"
          }}>{`trouver cinq personnes motivées`}</a>{`, écoutez leurs commentaires (en particulier les parties critiques) et intégrez-les dans votre workflow à travers de nombreuses itérations.`}</p>
        <Button to="https://www.notion.so/How-to-run-user-tests-fast-0e3ea190c9a34afabeb7eef9fdd7ef69" sx={{
          alignSelf: 'flex-start',
          mt: 'auto'
        }} mdxType="Button">
  Test
        </Button>
      </Flex>
    </Column>
    <h2 {...{
      "id": "conceptions-desquisse",
      "style": {
        "position": "relative"
      }
    }}>{`Conceptions d'Esquisse`}<a parentName="h2" {...{
        "href": "#conceptions-desquisse",
        "aria-label": "conceptions desquisse permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Nous ne pouvons pas fournir autant d'alpha ici, mais vous trouverez ci-dessous les six meilleurs outils que nous connaissons et utilisons pour des croquis rapides et des wireframes, particulièrement utiles dans les conversations avec d'autres membres de l'équipe pour illustrer visuellement vos idées et collaborer sur un support qui ne privilégie pas les mots.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Excalidraw`}</strong></p>
          <p>{`Application de croquis open-source, collaborative et cryptée e2e. Faut-il en dire plus ?`}</p>
          <p><em parentName="p">{`H/T Adrian le Bas`}</em></p>
          <Button to="https://excalidraw.com/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Explore
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Machinations`}</strong></p>
          <p>{`Un outil destiné aux concepteurs de jeux, utile pour la conception de mécanismes et l'économie des jetons.`}</p>
          <p><em parentName="p">{`H/T James Young`}</em></p>
          <Button to="https://machinations.io/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Mechanise
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Balsamiq`}</strong></p>
          <p>{`Logiciel de wireframing rapide, efficace et ludique.`}</p>
          <Button to="https://balsamiq.com/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Wireframe
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Whimsical`}</strong></p>
          <p>{`Un espace de travail visuel. Vous pouvez créer de plus beaux organigrammes plus facilement ici que dans Diagrams.net, mais peut-être pas aussi étendus ou compliqués.`}</p>
          <p><em parentName="p">{`H/T Nazzareno Massari`}</em></p>
          <Button to="https://whimsical.com/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Visualise
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`sketch.systems`}</strong></p>
          <p>{`Un outil pour prototyper des machines d'état. Utile pour les sessions de tableau blanc et itérer rapidement avec des conceptions avec lesquelles vous pouvez jouer en temps réel.`}</p>
          <p><em parentName="p">{`H/T Mike Ryan`}</em></p>
          <Button to="https://sketch.systems/Linknon/sketch/new" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Sketch
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Loom`}</strong></p>
          <p>{`L'expressivité de la vidéo avec la commodité de la messagerie. Facilement lié à GitHub. Idéal pour la révision de code et la conception collaborative.`}</p>
          <p><em parentName="p">{`H/T Jo-Ann Hamilton`}</em>{`.`}</p>
          <Button to="https://www.loom.com/use-cases/engineering" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Record
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "design-de-systèmes",
      "style": {
        "position": "relative"
      }
    }}>{`Design de systèmes`}<a parentName="h2" {...{
        "href": "#design-de-syst%C3%A8mes",
        "aria-label": "design de systèmes permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Construire le système de conception unique pour tous les composants Web3 est un rêve de longue date pour de nombreuses personnes différentes dans le Web3. Après tout, si tout est modulaire et interopérable, ne devrions-nous pas simplement pouvoir construire une bibliothèque de bonnes pratiques/standard pour les composants critiques - connecter votre portefeuille, signer des transactions, afficher les statuts des transactions, etc. - que tout le monde peut réutiliser ?`}</p>
    <p>{`Cela semble éminemment raisonnable, et comme beaucoup d'idées tout aussi raisonnables, il a été plus difficile à réaliser dans la pratique - voir le Lorikeet (Aragon) et Rimble (Consensys) désormais obsolètes pour preuve. Cependant, nous présentons le système de conception utilisé par ce site comme un exemple vivant d'une approche qui peut être plus durable.`}</p>
    <p>{`MakerDAO l'a construit afin de maintenir un langage visuel cohérent à travers les actifs de la Maker Foundation et les sites gérés par la communauté, tout en permettant des différences clés qui (i) indiquaient clairement quels sites et documents étaient gérés par qui et (ii) pour éviter de se tromper des réglementations légales auxquelles la Fondation devait se conformer. Il a donc une grande raison d'être, bien pensé et très bien conçu.`}</p>
    <Column mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`DAI-UI`}</strong></p>
        <p>{`Bien que dans les premiers stades, il existe quelques recettes utiles ici. Il utilise également theme-ui, alors consultez cette bibliothèque pour tous vos besoins de conception standard, en particulier si vous construisez avec React.`}</p>
        <Button to="https://design-system.mkr-js-prod.now.sh/recipes" sx={{
          alignSelf: 'flex-start',
          mt: 'auto'
        }} mdxType="Button">
  Craft
        </Button>
      </Flex>
    </Column>
    <h2 {...{
      "id": "design-despace",
      "style": {
        "position": "relative"
      }
    }}>{`Design d'espace`}<a parentName="h2" {...{
        "href": "#design-despace",
        "aria-label": "design despace permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Toutes nos ressources sont destinées à révéler la nature interdisciplinaire du travail dans le web3. Par conséquent, avant de commencer à coder, nous pensons que vous devriez explorer tout l'espace de conception en réfléchissant très attentivement au `}<strong parentName="p">{`pourquoi profond`}</strong>{`. Pourquoi construisez-vous ce produit particulier par opposition à toute autre chose ? Lorsque vous trouvez ce que vous pensez être une réponse, posez-vous à nouveau la question et continuez à chercher à mieux comprendre vos motivations et vos intentions réelles. Nous promettons que cela vous aidera à écrire beaucoup moins de code, et ce sera beaucoup plus génial.`}</p>
    <Column mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Hypersprint`}</strong></p>
        <p>{`Basé sur le livre de conception perspicace du même nom, il s'agit d'un modèle Miro que vous pouvez bifurquer et utiliser pour parcourir rapidement de nouvelles idées avec votre propre équipe. Nous l'utilisons pour Kernel et recommandons le processus d'identification du travail pratique le plus percutant que votre équipe peut entreprendre.`}</p>
        <Button to="https://miro.com/miroverse/category/workshops/hypersprint" sx={{
          alignSelf: 'flex-start',
          mt: 'auto'
        }} mdxType="Button">
  Consider
        </Button>
      </Flex>
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Outils de conception pour les développeurs`}</strong></p>
        <p>{`Maintenant que vous avez réfléchi à votre pourquoi, vous aurez besoin d'outils de prototypage simples pour parcourir les commentaires des utilisateurs que vous avez pu recueillir. Cette liste d'outils est aussi complète que toutes celles que nous connaissons et vous mènera à de nombreuses ressources intéressantes. La liste est limitée aux outils gratuits que vous pouvez utiliser immédiatement.`}</p>
        <Button to="https://github.com/bradtraversy/design-resources-for-developers" sx={{
          alignSelf: 'flex-start',
          mt: 'auto'
        }} mdxType="Button">
  Begin
        </Button>
      </Flex>
    </Column>
    <p>{`Nous avons inclus un enregistrement d'Andrej décrivant le processus Hypersprint au cas où vous trouveriez le modèle Miro lié ci-dessus un peu déroutant au début. L'essentiel est de continuer à bouger, de jouer de la bonne musique et de s'amuser.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/GgPfP_3cR9U?start=137" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      